import { useTranslation } from "react-i18next";
import { useScreenSize } from "../electrify_frontend_common/hooks/useScreenSize";
import { useGtmTracking } from "./useGtmTracking";



export function useMoreInfo(product: 'evFinder' | 'quickCheck') {

    const {i18n} = useTranslation();
    const {widthIsLessThan1024} = useScreenSize();


    const { evFinderEventTracking } = useGtmTracking();

    const openVehicleDetails = (evId: string) => {
        evFinderEventTracking({ event: 'buttonClick', value: 'moreInfo', product, page: product === 'quickCheck' ? 'results' : '-'});
        const url = process.env.REACT_APP_ENV === "development"
            ? `/ev-finder/ev-details?evId=${evId}`
            : `${process.env.REACT_APP_WEBSITE_URL}/${i18n.language}/drivers/ev-finder/ev-search?evId=${evId}`;

        if (widthIsLessThan1024) window.open(url, "_top");
        else window.open(url, "_blank");
    }

    return { openVehicleDetails};


}