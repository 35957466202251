import { ReactElement, createContext, useState } from "react";
import { IFilters } from "../electrify_frontend_common/types";
import { useGtmTracking } from "../hooks/useGtmTracking";

export const alternativesSortMap: {
    label: string;
    key: string;
    order: "ASC" | "DESC";
}[] = [
        {
            label: "listingPrice",
            key: "listPrice",
            order: "ASC",
        },
        {
            label: "rangePerPrice",
            key: "rangeMetersPerDePriceK",
            order: "DESC"
        },
        {
            label: "realRange",
            key: "range.real.average",
            order: "DESC",
        },
        {
            label: "realConsumption",
            key: "testedConsumption100km.real",
            order: "ASC",
        },
        {
            label: "make",
            key: "modelInfo.make",
            order: "ASC",
        },
        {
            label: "loadVolume",
            key: "physicalInfo.volume.maximum",
            order: "DESC",
        },
    ];



export const EvFinderContext = createContext<{
    filters: IFilters,
    updateFilters: ((filters: Partial<IFilters>) => void),
    sortBy: {
        label: string;
        key: string;
        order: "ASC" | "DESC";
    },
    updateSortBy: (sortBy: {
        label: string;
        key: string;
        order: "ASC" | "DESC";
    }) => void,
    page: number,
    setPage: ((page: number) => void),
    resetFilters: () => void
}>({
    filters: {} as IFilters,
    updateFilters: () => null,
    sortBy: {} as any,
    updateSortBy: () => null,
    page: 1,
    setPage: () => null,
    resetFilters: () => null
})


export function EvFinderContextProvider({ children }: { children: ReactElement }) {

    const [filters, setFilters] = useState<IFilters>({
        brands: [],
        availabilityStatus: null,
        listPrice: { min: null, max: null },
        topSpeed: null,
        realRange: null,
        realConsumption: null,
        loadWeight: null,
        loadVolume: null,
        seats: null,
        "4x4": null,
        fastCharging: false,
        towing: false,
        plugAndCharge: false,
        vehicleToGrid: false,
        isofixAvailable: false,
        roofRails: false,
    });

    const resetFilters = () => {
        setFilters({
            brands: [],
            availabilityStatus: null,
            listPrice: { min: null, max: null },
            topSpeed: null,
            realRange: null,
            realConsumption: null,
            loadWeight: null,
            loadVolume: null,
            seats: null,
            "4x4": null,
            fastCharging: false,
            towing: false,
            plugAndCharge: false,
            vehicleToGrid: false,
            isofixAvailable: false,
            roofRails: false,
        })
    }

    const [sortBy, setSortBy] = useState(alternativesSortMap[0]);
    const [page, setPage] = useState(1);

    const { evFinderEventTracking } = useGtmTracking();

    const updateFilters = (updatedProps: Partial<IFilters>) => {

        evFinderEventTracking({event: 'valueUpdated', value: 'filters', product: 'evFinder', page: '-'});

        setPage(1);
        setFilters((currentFilters: IFilters) => {
            return {
                ...currentFilters,
                ...updatedProps,
            };
        });
    }

    const updateSortBy = (newSortBy: any) => {
        evFinderEventTracking({event: 'valueUpdated', value: 'sorting', product: 'evFinder', page: '-'});
        setPage(1);
        setSortBy(newSortBy);
    }

    return (
        <EvFinderContext.Provider value={{
            filters,
            updateFilters,
            sortBy,
            updateSortBy,
            page,
            setPage,
            resetFilters
        }}>
            {children}
        </EvFinderContext.Provider>
    )

}