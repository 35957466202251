import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

declare global {
    interface Window {
        dataLayer: any;
    }
}

const gtmId = process.env.REACT_APP_GTM_ID;

window.dataLayer = window.dataLayer || [];

console.log("GTM ID: ", gtmId);
console.log("GTM AUTH: ", process.env.REACT_APP_GTM_ENV_AUTH);
console.log("GTM_ PREVIEW: ", process.env.REACT_APP_GTM_ENV);

if (gtmId)
    TagManager.initialize({
        gtmId,
        auth: process.env.REACT_APP_GTM_ENV_AUTH,
        preview: process.env.REACT_APP_GTM_ENV,
        
    });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<React.StrictMode>
    <App />
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
